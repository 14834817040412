.facts {
  padding-top: 100px;
  padding-bottom: 100px;

  .facts-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 200px;
    .fact {
      width: 25%;

      @media screen and (max-width: $smalldesktop) {
        width: 50%;
        margin-bottom: 30px;
      }
    }
    .fact-icon {
      width: 60px;
      height: 60px;
      margin: auto;
      margin-bottom: 20px;
      .image {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center; 
      }
    }
    .fact-number {
      display: block;
      font-size: 80px;
      text-align: center;
      font-weight: 400;
      letter-spacing: -2.8pt;
      @media screen and (max-width: $smalldesktop) {
        font-size: 40px;
        letter-spacing: -0.8pt;
      }
    }
    span {
      font-size: 18px;
      opacity: 0.5;
      display: block;
      width: calc(100% - 20px);
      text-align: center;
      line-height: 26px;
    }
    h3 {
      width: calc(100% - 20px);

      margin: auto;
      margin-top: 34px;
      text-align: center;
    }
    .fact-icon {
    }
  }
}
