

.reviews {
  padding-top: 100px;
  padding-bottom: 100px;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    cursor: pointer;
    display: flex;
    justify-content: center;

    .swiper-slide-inner {
      text-align: center;
      font-size: 18px;
      width: calc(100% - 140px);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      @media screen and (max-width: $tabletsize) {
        width: 100%;
      }
    }

    h2{
      font-size:28px; 
    }
    .portrait {
      width: 100px;
      height: auto;
      margin-bottom: 15px;
    }
    .name {
      font-weight: 700;
    }
    .position {
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $blue;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: $blue; /* Farbe der Pfeile */
    width: 30px;
    height: 30px;
    @media screen and (max-width: $tabletsize) {
      display: none;
    }
  }

  .rewiewSwiperAlternative {
    position: relative;
    //padding: 0 60px; /* Platz für die Pfeile links und rechts reservieren */
  }

  .swiper-button-next {
    right: 10px; /* Abstand von der rechten Seite des Sliders */
    @media screen and (max-width: $tabletsize) {
    }
  }

  .swiper-button-prev {
    left: 10px; /* Abstand von der linken Seite des Sliders */

    @media screen and (max-width: $tabletsize) {
    }
  }
}  
