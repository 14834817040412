
// @import "variables";
// @import "fonts";
// @import "mixins";

// Common
@import "variables";
@import "global";
@import "forms";

@import "fonts";

@import "structure/header";  
@import "structure/footer";      
@import "structure/menu";    
   
@import "blocks/hero";   
@import "blocks/image-text";    
@import "blocks/image-text-repeater.scss";  
   
// @import "blocks/teammember";        
// @import "blocks/infos";          
// @import "blocks/page-header";            
// @import "blocks/tiles";       
 @import "blocks/facts";       
 @import "blocks/team";         
 @import "blocks/services";         
 @import "blocks/references";       
      
@import "blocks/reviews";     
       
@import "blocks/contact";         
@import "blocks/locations";         
     
@import "blocks/textblock";         
   
@import "blocks/publications";          
@import "blocks/jobs";   
 
@import "blocks/accordion";  

 
@import "blocks/podcast";   
// @import "blocks/textblocks";                   
// @import "blocks/teaser";             
// @import "blocks/video";            
// @import "blocks/galerie";                 
// @import "blocks/jobs";         
// @import "blocks/apply";          
// @import "blocks/contact";              
// @import "blocks/application";           
// @import "blocks/reasons";     
// @import "blocks/intro";          

   