html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif; 
}
 


#wrapper {
  width: 100%;
  height: auto;
  margin: auto;

  position: relative;
  // .inner-wrapper {
  //   width: 80%;
  //   background-color: rgba(255, 255, 255, 0.7);
  //   margin: 2.5%;
  //   margin-bottom: 0px;
  //   padding-bottom: 30px;

  // }
  .inner-wrapper{
    // padding-top: 80px;
    a{
      color:$blue;
    }
  }
}


.content{
padding-top:90px;
}
 
 
.center{    
  width: 80%;
    max-width: 1200px;
  margin:auto;
  @media screen and (max-width: $tabletsize) {
    width: 80%;
    
  }
}


.overflow-hidden{
  overflow:hidden;
}


// button[type=submit],
// .btn{
//   outline: none;
//   border: none;

//   cursor:pointer;

//   font-family: "Roboto Condensed";

//   letter-spacing: 1.2pt;
//   font-size: 18px;
//   font-family: "Roboto Condensed";
//   font-weight: 400;

//   background-color: #132a4f;
//   color:white;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   // text-transform:uppercase;

//   padding-left: 20px;
//   padding-right: 20px;
//   border-radius: 55px; 
//   min-width: 140px;
//   height: 55px;
//   box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
//   transition: all 0.2s ease;
//   span{
//     width:calc(100% - 20px);
//   }

//   .btn-icon{ 
//     background-image: url("/wp-content/themes/acasa/img/icons/icon-arrow-right-green.svg");
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center;
//     width:20px;
//     height:20px;
//     margin-left: 10px;
//     position: relative;
//     right:0;
//     transition: all 0.2s ease;

//   }
//   &:hover{
//     box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0);
//     .btn-icon{
//       right:-5px;
//     }
//   }

// }


.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  *zoom: 1; /* Für den IE6 und IE7 */
}

@media screen and (max-width: $tabletsize) {
  .col {
    width: 100% !important;
    margin: 0 !important;
  }
}

.four-three {
  position: relative;
  overflow: hidden;
}
.four-three:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 67%;
}
.four-three > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}



.wp-block-embed{
  margin: 0;
}
.wp-block-embed__wrapper{
  aspect-ratio: 16 / 9;

  iframe{
    width:100%;
    height:100%;

  }
}

.wp-block-image{

  &.size-large{
    margin: 0;
    width:100%;
    img{
      width:100%; 
    }
  }
}


// .page-id-50{
//   .image-text{
//     .col{
//       background-size:contain;
//     }
//   }
// }

img{
max-width: 100%;
display: block;
}

.center-text{
  max-width:800px;
}

iframe{
  aspect-ratio:16/9; 
}

.iframe-container{
  iframe{aspect-ratio:auto;}
}