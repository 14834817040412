#header {
  nav {
    @media screen and (max-width: $smalldesktop) {
      display: none;

      position: absolute;
      top: 90px;
      height: calc(100vh - 90px);
      width: 100%;
      left: 0;
      right: 0;

      bottom: 0;
      background-color: rgba(255, 255, 255, 0.9); 
    }
  }
  .menu-haupmenue-en-container,
  .menu-hauptmenue-container {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      @media screen and (max-width: $smalldesktop) {
        flex-direction: column;
        padding-top: 20px;
      }
      li {
        // position: relative;
        margin-left: 20px;
        margin-right: 20px;

        height: 90px;
        display: flex;
        align-items: center;
       

        a {
          font-size: 18px;
          font-weight: 400;
          text-decoration: none;
          color: $black;
          display: block;
        }

        &.current-menu-item{
          a{
          color:$blue;
          }
          ul li a{color:black;}
        }

        @media screen and (max-width: $smalldesktop) {
          height: auto;
          margin: auto;

          &:not(.contact-button){
          flex-direction: column;
          align-items: flex-end; 
      
          }
&.contact-button{
  margin-right: 10%;
  margin-top:10px; 
}

          width: calc(80% - 30px);

          a{
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 14px !important;
            font-weight: 700 !important;
            text-transform: uppercase;
          }
          &.contact-button a{ 
            font-size: 18px !important;
            font-weight: 400 !important; 
            text-transform: none;
          }
        }

        &.contact-button {
          height: 20px;
          border: 1px solid $lightblue;
          padding: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 40px;
          border-radius: 30px;
          position: relative;
          background-color: #eff3f5;

          @media screen and (max-width: $smalldesktop) {
            width: 100px;
          }

          &:after {
            content: "";
            width: 20px;
            height: 20px;

            display: block;
            position: absolute;
            right: 15px;
            background-image: url("../../img/icon-contact.svg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        } // end contact button

      
        &:hover {
          ul.sub-menu {
            opacity: 1;
            overflow: auto;
            height: auto;
          }
        }
      }
    }
  }
  ul.sub-menu {
    opacity: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 90px;

    padding-top: 10px;
    padding-bottom: 10px;

    -webkit-box-shadow: 0px 8px 17px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 8px 17px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 8px 17px -10px rgba(0, 0, 0, 0.75);
    width: 200px;
    background-color: white;

    @media screen and (max-width: $smalldesktop) {
      position: relative;
      top: 0;
      opacity: 1;
      height: auto;
      width: auto;
      padding: 0;
      background-color: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      width: 100%;
      padding-bottom:15px;
    }

    li {
      margin: 0;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 10px;
      padding-right: 10px;
      width: calc(100% - 20px);
      cursor: pointer;

      height: auto;

      @media screen and (max-width: $smalldesktop) {
        padding: 0;
        width: 100%;
      }

      &:last-child {
        /* Rücksetzen der :last-child-Stile für Submenüs, falls nötig */
        height: auto;
        border: none;
        padding: 0;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 0;
        position: relative;
        background-color: transparent;
        @media screen and (max-width: $smalldesktop) {
          width: 100%;
          padding: 0;
        }
        &:after {
          content: none;
        }
      }
      a {
        display: block;
        @media screen and (max-width: $smalldesktop) {
          font-size: 18px !important;
          line-height: normal;
          font-weight: 400 !important;
          text-transform:none;
        }
      }
      &:hover {
        background-color: $lightblue;
        @media screen and (max-width: $smalldesktop) {
          background-color: transparent;
        }
       
      }
    }
  }
}
