$mobilesize:600px;
$tabletsize:768px;
$smalldesktop:1024px;
$desktopsize:1200px;

$white:white;
$black:#000;
$grey:#333333;
$lightgrey:#F4F6F8;


$corporatecolor: #0D60AC; 
//$lightblue: #DAE2EA;

$lightblue: #eff3f7;
$lightblue:#e8ebee;
$blue: #21D0FA; 
 




.bg-white {
    background-color:$white;
}
.bg-lightblue{
    background-color: $lightblue;
}
.bg-blue {    background-color: $blue;} 
.bg-darkblue {
    background-color:$corporatecolor;
}
