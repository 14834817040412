.podcast{
    .iframe-container {
        position: relative;
    }    
    .iframe-container iframe {
        width: 100%;
        height: 232px;
    }

    @media only screen and (max-width: 684px) {
        .iframe-container iframe {
            height: 450px;
        }
    }
    // #app .antialiased{ 
    //     max-width: 100% !important;
    // }
}