@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-BlackItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Black.woff2") format("woff2"),
    url("../../fonts/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-ThinItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-LightItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Light.woff2") format("woff2"),
    url("../../fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Italic.woff2") format("woff2"),
    url("../../fonts/Roboto-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../../fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-MediumItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-BoldItalic.woff2") format("woff2"),
    url("../../fonts/Roboto-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Light.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-LightItalic.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Bold.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Regular.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-BoldItalic.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Italic.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: 'Cardo';
  src: url('../../fonts/Cardo-Bold.woff2') format('woff2'),
      url('../../fonts/Cardo-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  //font-display: swap;
}

@font-face {
  font-family: 'Cardo';
  src: url('../../fonts/Cardo-Italic.woff2') format('woff2'),
      url('../../fonts/Cardo-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  //font-display: swap;
}

@font-face {
  font-family: 'Cardo';
  src: url('../../fonts/Cardo-Regular.woff2') format('woff2'),
      url('../../fonts/Cardo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  //font-display: swap;
}


 
body,html {
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
}


 .headline,
a, p, ul li {
  line-height: 30px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.8pt;

  @media screen and (max-width: $smalldesktop) {
    font-size: 16px;
    line-height: 26px;
    } 

}


.headline{
color: black;
font-weight: 400;
}

a{
  color: black;
    font-weight: 400;
    &:hover{
      //color:$corporatecolor; 
      text-decoration:none; 
    }
}




h1,
h2,
h3,
h4 {
  font-family: "Roboto Condensed";

}

h1.wp-block-heading{
  padding-top:50px;
}

h1 {

  // font-size: 80px;
  // font-weight: 200; 
  // line-height: 80px;
  // margin-top: 20px;
  // -webkit-font-smoothing: subpixel-antialiased; 
  // letter-spacing: -1.6pt;

  font-family: "Roboto Condensed";
  color:$corporatecolor;

    // font-family: 'Cardo'; 
    // font-style:italic;
    //color:$corporatecolor;

    font-size: 60px;
    font-weight: 700;
    line-height: 65px;
    margin-top: 20px;
    -webkit-font-smoothing: subpixel-antialiased;
    letter-spacing: -1.6pt;
    text-transform: uppercase;
    span{
    
      text-transform: uppercase;

      font-weight: 500;
      color:$blue; 
    }
  // margin-left: -34px;
  @media screen and (max-width: $tabletsize) {
    font-size: 40px;
    line-height: 40px;
  }
}

// .fact-number,
h2 {
  font-family: 'Cardo'; 
  //font-family: "Roboto Condensed";
  font-size: 40px; 
  font-weight: 700; 
  line-height:normal;
  -webkit-font-smoothing: subpixel-antialiased; 
  letter-spacing: -0.5pt;
  @media screen and (max-width: $tabletsize) {
    font-size: 24px;
    } 

}


h3, h3 a {
  font-size: 26px; 
  line-height:normal;
  font-weight: 400;
  margin-bottom:35px;
  letter-spacing: .4pt;
  -webkit-font-smoothing: subpixel-antialiased; 
  text-decoration: none;


  @media screen and (max-width: $tabletsize) {
    font-size: 22px;
    line-height: 22px; 
    } 
 
} 
 