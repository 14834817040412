.image-text{
     display: flex; 
    // justify-content: space-between;
    // @media screen and (max-width: $tabletsize) {
    //     flex-direction:column;      
    // }

    // &:nth-child(2n) {
    //     flex-direction: row-reverse;
    //     @media screen and (max-width: $tabletsize) {
    //         flex-direction:column;      
    //     }
    //   }

    .center{
        display: flex; 
        justify-content: space-between;
        &.return{
            flex-direction: row-reverse;
        }
    }
    .col{
   
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        aspect-ratio: 1/1;
        margin: 0;
        display: flex;
        // justify-content: center;
        align-items: center;
        // @media screen and (max-width: $tabletsize) {
  
        //    width:100%;
                
        // }
        .col-inner{
            margin:20px; 
        //     width:calc(100% - 40px);
        //     @media screen and (max-width: $tabletsize) {
        //         padding-top:30px;
        //         padding-bottom:30px;         
        //       }
         }
    } 
} 