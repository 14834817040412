
.image-text-repeater{
    padding-top:100px;
    padding-bottom:100px;
    .image-text-repeater-inner{
   
        // max-width:1000px !important;
    }

    .image-text-repeater-wrapper{
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap; 
        padding-top: 30px; 
        padding-bottom: 30px;
        flex-direction: row-reverse; 
        h2{
            margin:0;
        }
        .content-inner{
            display: flex;
            flex-direction: column;
            width:100%;  
        }
         &:nth-child(2n) {
            flex-direction: row;
            h2{align-self: flex-end;}
        @media screen and (max-width: $tabletsize) {
            flex-direction:column;      
        }
      }
    }

    .content{
        width:calc(100% - 240px); 
        padding: 0; 
        display: flex;
        align-items:center;

        .content-inner{
      
        }
    }
    .image{
        aspect-ratio: 1/1;
        background-size:contain;
        background-repeat: no-repeat;
        background-position: center;
        width:200px;
        clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
        img{
            height: 100%;
            width: auto;
        }
    }
}








.image-text-repeater-alternative{
    padding-top:100px;
    padding-bottom:100px;
    .image-text-repeater-inner{
   
        // max-width:1000px !important;
    }

    .image-text-repeater-wrapper{
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap; 
        padding-top: 30px; 
        padding-bottom: 30px;
        flex-direction: row-reverse; 
        h2{
            margin:0;
        }
        .content-inner{
            display: flex;
            flex-direction: column;
            width:100%;  
        }
         &:nth-child(2n) {
            flex-direction: row;
            h2{align-self: flex-end;}
        @media screen and (max-width: $tabletsize) {
            flex-direction:column;      
        }
      }
    }

    .content{
        width:calc(100% - 240px); 
        padding: 0; 
        display: flex;
        align-items:center;

        .content-inner{
      
        }
    }
    .image{
        aspect-ratio: 1/1;
        background-size:contain;
        background-repeat: no-repeat;
        background-position: center;
        width:200px;
        clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
        img{
            height: 100%;
            width: auto;
        }
    }
}