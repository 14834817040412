.references {
  padding-top: 100px;
  padding-bottom: 100px;
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 80px;
    #search-form {
      width: 30%;
      @media screen and (max-width: $tabletsize) {
        width: 100%;
        margin-top: 20px;
      }
    }
    .tabbar {
      width: 50%;
      @media screen and (max-width: $tabletsize) {
        width: auto;
        margin:auto; 
      }
    }
  }

  .info-text{
    width: 100%;
    text-align: center;
    font-weight:400; 
    font-size:18px; 

  }

  #search-form input[type="text"] {
    width: 100%;

    height: 45px;
    line-height: 45px;
    border: none;

    outline: none;

    border-radius: 25px;
    background-color: #eff3f5 !important;
    border: none !important;
    border-bottom: 1px solid $lightblue !important;

    text-indent: 10px;
    font-size: 18px;
  }

  .tabbar {
    padding: 0;
    margin: 0;
    display: flex;
    li {
      margin: 20px;
      list-style: none;
      @media screen and (max-width: $tabletsize) {
        margin: 5px;
      }
      a {
        font-size: 18px;
        font-weight: 400;
        text-decoration: none;
        letter-spacing: 0.8pt;
        color: #000;

        &.active {
          color: $corporatecolor;
        }
      }
    }
  }

  .references-wrapper {
    display: flex;
    flex-direction: column;
  }

  .references-inner {
    min-height: 1000px;
    .grid-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;

      @media screen and (max-width: $smalldesktop) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: $tabletsize) {
        grid-template-columns: repeat(3, 1fr);
      }
      .grid-item {
        // width:25%;
        position: relative;
        .name {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
        .portrait {
          aspect-ratio: 16/9;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: white;
          img {
            width: calc(100% - 60px);
            filter: grayscale(100%);
            transition: filter 0.3s ease-in-out;
            @media screen and (max-width: $tabletsize) {
              width: calc(100% - 6px);
            }
          }
          &:hover {
            img {
              filter: grayscale(0%);
            }
          }
        }
      }
    }
  }
}

.references-preview {
  .references-inner {
    min-height: auto;
  }

  .portrait {
    aspect-ratio: 1/1 !important;
  }
}
