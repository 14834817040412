header {
  width: 100%;
  height: 90px;
 
  position: fixed;
  z-index: 100;
  background-color: rgba(255,255,255,1);
  // border-bottom: 1px solid rgba(240,240,240,0);
  transition: all .5s ease;
  box-shadow: 0 0 40px #0000000d; 
  // &.scrolled{
  //   background-color: rgba(255,255,255,1);
  //   border-bottom: 1px solid rgba(240,240,240,1);
  // }
  // .contact-header{
  //   background-color: #F8D448;
  //   height: 100%; 
  //   width: 250px; 
  // }

  .header-inner {
    display:flex;
    justify-content:space-between;
    align-items:center;
    height: 100%;

    .logo {

      background-image: url("../../img/logo-compact.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center; 
      // background-color:$corporatecolor;
      display: block;
      // height: 160px;
      width: 250px;
      height:60px;
      margin-top:15px;
      margin-bottom:15px;

      // float:left;
      position: relative;

    }

  }

}






.hamburger {
  // padding: 15px 15px;
  // padding-left: 0px;
  // display: inline-block;
  // cursor: pointer;
  // transition-property: opacity, filter;
  // transition-duration: 0.15s;
  // transition-timing-function: linear;
  // font: inherit;
  // color: inherit;
  // text-transform: none;
  // background-color: transparent;
  // border: 0;
  // margin: 0;
  // overflow: visible;
  // position: relative;

  // width: 40px; 
  // height: 24px;
  display:none !important;  

  @media screen and (max-width: $smalldesktop) {
    display: block !important;  
  }


  font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    z-index: 10000;

}
.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 25px;
  height: 20px;
  margin-top: 6px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 25px;
  height: 2px;
  background-color: black;
  // background-color: $contrastcolor; 
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s; 
  transition-timing-function: ease;
}

.home{
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {

    background-color: black;
  }
  // .scrolled{
  //   .hamburger-inner,
  //   .hamburger-inner::before,
  //   .hamburger-inner::after {
  
  //    // background-color: $contrastcolor;  
  //   }

  // }
}

.is-active{
  // .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {

    // background-color: white !important;
  }
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: 7px;
}
.hamburger-inner::after {
  top: 14px;
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 7px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
  transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
  transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

// .hamburger {
//   display: none;
// }

.show {
  display: block !important;
}
