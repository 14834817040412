.team {

    padding-top: 50px;
    padding-bottom: 50px;

    h3 a{
      text-decoration: underline;
    }

  .portrait {
    aspect-ratio: 1 / 1;
    background-size: cover;
    // img{
    //     clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    // }

    position: relative;
    top: -50px;
  }

  .portrait img {
    transition: transform 0.3s ease; /* Animationsdauer und -effekt */
}


/* Wenn irgendein Element innerhalb von .teammember-inner gehovered wird */
.teammember-inner:hover .portrait img {
  transform: scale(1.05); /* Skaliert das Bild um 10% */
}
  

  .grid-container.clevel {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .grid-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 20px;
    padding-bottom: 20px;
    @media screen and (max-width: $tabletsize) {
      margin-bottom:0; 
         } 
   
      &:nth-child(even) {
        .teammember-inner {   
            flex-direction: row-reverse;
        }
     
      }
      .teammember-inner {
        width: calc(100% - 40px);
        display: flex;
        margin: auto;

    @media screen and (max-width: $tabletsize) {
      flex-wrap:wrap;
         } 

      }
      .portrait {
        width: 33.3%;
        // background-color: $lightblue;
        aspect-ratio: 1/1;
        @media screen and (max-width: $tabletsize) {
          width:70%;
          margin:auto; 
             } 
      }
      .member-content {
        width: 66.6%;

        display: flex;
        flex-direction: column; 
        justify-content: center;
        margin: 20px;
        margin-top:0;
        @media screen and (max-width: $tabletsize) {
        width:100%;
           } 
        h3{
            margin:0;
            margin-bottom:6px;
        }
      }
    }
  }

  .grid-container.founder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; /* Abstand zwischen den Spalten und Zeilen */
    @media screen and (max-width: $tabletsize) {
      display: flex;
      flex-wrap: wrap;
      gap:0;
      } 
    .grid-item {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      padding-bottom: 20px;
      @media screen and (max-width: $tabletsize) {
        flex: auto;
        width:100%;
        margin-bottom:20px;
         } 
      .teammember-inner {
        width: 100%;
        display: flex;
    flex-direction: column;
    align-items: center;

      }
      .portrait {
        width: 70%;
        // background-color: $lightblue;
        aspect-ratio: 1/1;
      }
      .member-content {
        width:80%; 
        margin-top: 0;
        h3{
          margin:0;
          margin-bottom:6px;
      }
      }
    }
  }

  .grid-container.coworkers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;/* Abstand zwischen den Spalten und Zeilen */
    @media screen and (max-width: $tabletsize) {
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between; 
    gap:0;
    }
    .grid-item {
      
        padding-bottom:40px;
        @media screen and (max-width: $tabletsize) {
         width:calc(50% - 20px);
          } 
      .portrait {
   

    position: relative;
    top: 0;
  
      }
      .name {
        display: block;
        font-weight: 700;
        font-size: 18px;
        padding-top: 10px;
      }
      .position {
        display: block;
        font-size: 18px;
      }
      p {
        font-size: 16px;
      }
      .member-content{
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
