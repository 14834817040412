.hero {
  width: 100%;
  padding-top: calc(90px + 10vh);

  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;

  position: relative;
  @media screen and (max-width: $tabletsize) {
    padding-bottom:  10vh;
    padding-top: 10vh;
  }
  .centered-content {
    width: 80%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .hero-content {
    width: 66.6%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: $tabletsize) {
      width: 100%;
   
    }
    .breadcrump {
      align-self: flex-start;
    }
  }
  .hero-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 33.3%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    z-index: 0;
    opacity: 0.6; 
    @media screen and (max-width: $tabletsize) {
      width: 100%; 
      opacity: 0.2;
    }
  }

  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 80%);
    height: 100%;
    background-image: inherit;
    background-size: cover;
    background-position: center right;
    z-index: 0;
  }

  .hero-intro {
    min-height: calc(30vh - 40px);
  }
}
