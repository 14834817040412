.accordion-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .headline {
    width: 30%;
    @media screen and (max-width: $mobilesize) {
      width: 100%;
    }
    h2 {
      margin-top: 10px;
    }
  }

  .accordion {
    width: 65%;
    @media screen and (max-width: $mobilesize) {
      width: 100%;
    }
    h3 {
      margin: 0;
      padding-bottom: 20px;
      padding-top: 20px;
      cursor: pointer;
      outline: none;
      border-top: 1px solid black;
    }
    p {
      margin: 0;
    }
  }

  .ui-accordion-content {
    padding-bottom: 20px;
  }
}

.accordion-list {
  padding-top: 100px;
  padding-bottom: 100px;
}
