.contact{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
 
.contact-form{
  width:66.6%; 
  padding-bottom: 80px;
  padding-top: 60px;
  @media screen and (max-width: $tabletsize) {
    width: 100%;
    }  
}

.contact-image{
  width:33.3%;
  @media screen and (max-width: $tabletsize) {
  display:none;
    }  
}
.af-field{
  padding-left: 0px !important; 
  padding-right:0px !important; 
}
  

    .af-input{
      textarea,
      input{
        background-color:$lightblue; 
      }
    }

    .af-submit {
        width: 100%;
        @media screen and (max-width: $tabletsize) {
          width: 100%;
        }
      }
      button[type="submit"] {
        @media screen and (max-width: $tabletsize) {
          width: 100%;
        }
      }


/** NEW **/ 


.centered-content {
  width: 80%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  z-index: 1; /* Stellt sicher, dass der Text über dem Hintergrundbild bleibt */
}


.contact-content{
  width:66.6%;
  height:100%;
  display:flex;   
 justify-content:center;
  flex-direction: column;
  span{
    align-self: flex-start;
    line-height: 28px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.3pt;
  }
}
.contact-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 33.3%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  z-index: 0; /* Stellt sicher, dass das Bild hinter dem Text bleibt */
}


&:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 80%);
  height: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center right;
  z-index: 0; 
}

}

.contact.fullwidth{
  .contact-form{
  width: 100%;
  padding-bottom: 80px;
  padding-top: 60px;
  max-width: 800px !important;
  margin: auto;
  }
  .contact-image{
    display:none; 
  }
}


