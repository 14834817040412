footer {
  //margin-top:100px;
  // height: 90px;
  // border-top:1px solid $lightgrey;
  background-color: $grey;

  // margin-top: 100px;

  position: relative;

  .footer-inner {
    color: white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .social-col{
      width: 80px;
      margin: 0;
    }
    .social {
      height: 80px;
      width: 200px;
      .social-icon {
        height: 80px;
        width: 80px;
      }
      .image {
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
      }
    }

    .menu-col {
   
        display:flex;
        flex:1;
        justify-content:flex-end;
      
      ul {
        padding: 0;
        list-style: none;
        display: flex;
        @media screen and (max-width: $tabletsize) {
        flex-direction:column;
         }
        li {
          padding: 0;
          margin: 0;
          margin-bottom: 15px;
          @media screen and (max-width: $tabletsize) {
            margin-bottom:0;
          }
          margin: 20px;
          @media screen and (max-width: $tabletsize) {
           margin:0;
          }
          a,
          p {
            color: white;
            font-size: 14px;
          }
      
        }
      }
    
    }

   
   

  
  }
}
