.jobs {
padding-top:100px;
  // margin-bottom: 200px;

  h2{
    text-align: left; 
    margin-bottom:50px;
  }
  .entry {
    // border-bottom: 1px solid #ddd;
    // display:flex;
    // justify-content:space-between;
    // flex-wrap:wrap;
    // padding-top:20px;
    // padding-bottom:20px; 
    display: block;

    text-align: left; 
    text-decoration: none; 
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom:1px solid $lightblue; 
    &:first-child{
      border-top:1px solid $lightblue;  
    }
    transition: all 0.3s ease-in-out;
    &:hover{ 
      background-color:$blue;
      color:black;
    }
    .entry-inner{
      width: calc(100% - 20px);
      // padding:20px;
      display:flex;
      justify-content:space-between;
      flex-wrap:wrap;
      padding:10px;
      h3, p{


    text-decoration: none; 
      }
      
    }
    h3{
        margin-top:0;
        width:calc(100% - 40px);
        margin-bottom: 5px;

    }
   
  }
  // .entry-icon{
  //   background-image: url("/wp-content/themes/acasa/img/icons/icon-arrow-up-blue.svg");
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   width:20px;
  //   height:20px; 
  // }
  
 
}
