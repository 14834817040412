

.services {
  // margin-top: 100px;
  // margin-bottom: 100px;
  padding-top: 100px;
  padding-bottom: 50px;
  .center-wide {
    display: flex;
    flex-wrap: wrap;
    width:90%;
    max-width: 1400px;
    border-top:1px solid $lightblue; 
    margin:auto;
    .service {
      flex: 1;
      display: flex; 
      cursor:pointer; 
      background-color:white; 
      transition: all 0.3s ease-in-out;
      @media screen and (max-width: $tabletsize) {
     flex:auto;
     width:100%;
     border-bottom: 1px solid $lightblue;
               } 
      &:hover{
        background-color:$blue; 
      }

      /* Linie zwischen den Spalten */
&:not(:last-child) {
  border-right: 1px solid $lightblue;/* Schwarze Linie als Trennstrich */
  @media screen and (max-width: $tabletsize) {
border-right:none;
       } 
}


      // aspect-ratio: 1/1;

      .service-inner {
        margin:20px;
        display:flex;
        flex-direction: column;
        .service-icon{
            width:50px;
            height:50px;
            background-size:contain;
            background-repeat: no-repeat;
          }
 
          .service-content{
            flex-grow: 1;
          }
        // padding-top: 40px;
        // padding-bottom: 40px;
        h3{
            // margin-top:0;
            margin-bottom:10px;

        }
      }
      &:nth-child(odd) {
        // background-color: $blue;
        // justify-content: flex-end;
        // margin-right: 40px;
        .service-inner {
        //   max-width: 580px;
        //   padding-right: 20px;
        }
      }
    }
  }
}
