.locations {
  // margin-top: 100px;
  // margin-bottom: 100px;
  padding-top: 100px;
  padding-bottom: 50px;
  .center-wide {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1400px;
    border-top: 1px solid $lightblue;
    margin: auto;
    @media screen and (max-width: $tabletsize) {
      flex-direction: column;
    }
    .location {
      flex: 1;
      display: flex;

      &:not(:last-child) {
        border-right: 1px solid $lightblue;
      }

      @media screen and (max-width: $tabletsize) {
        width: 100%;
        border-right: none !important;
      }

      .location-inner {
        width: 100%;
        margin: 20px;
        display: flex;
        flex-direction: column;
        .location-icon {
          width: 50px;
          height: 50px;
          background-size: contain;
          background-repeat: no-repeat;
        }

        .location-content {
          flex-grow: 1;
        }

        h3 {
          margin-bottom: 10px;
        }
      }
      &:nth-child(odd) {
        .location-inner {
        }
      }
    }
  }
}
