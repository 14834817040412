.textblock{
    display: flex;
    .textblock-wrapper{ 
        display: flex;
        justify-content: space-between; 
        padding-top:100px; 
        padding-bottom:100px;
    }
    .headline{ 
        width:35%;
        margin-top: 18px; 
    }
    .text{
        width:65%; 
    }
} 