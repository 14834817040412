.af-field {
    border-top:none !important;
    padding-left: 0;
    padding-right: 0;
  
    select,
    input[type=email],
    input[type=text],
    input[type=number]{
        height: 45px; 
        line-height: 45px;
        border: none;
  
        outline:none;
        // background-color:#f2f7f6;
        // border:1px solid #f0f0f0;

        border-radius: 0 !important;
    background-color: #EFF3F5 !important;
    border: none !important;
    border-bottom: 1px solid $lightblue !important;

    } 
 
   textarea{
        height: 200px;  
        resize: none; 
        outline:none;
        // background-color:#f2f7f6;
        border: none;
       
        // border:1px solid #f0f0f0;
        border-radius: 0 !important;
    background-color: #EFF3F5 !important;
    border: none !important;
    border-bottom: 1px solid  $lightblue  !important;
    } 
    ul {
        padding:0;
        margin:0;
        li{
            padding:0 !important;
            &:before{
                display: none;
            }
        }
    }

    &.af-field-type-radio{
        display:flex;
        width: 100%;
        justify-content: space-between;
        .acf-radio-list{
            display: flex;
        }
    }

    .message{
        font-size: 9pt;
        color: #888;
    }


}  
.af-field-type-true-false{
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
}

.af-submit{
    button[type=submit]{    
  
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;


    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none; 

 
    border: 1px solid $lightblue; 
    padding: 20px !important;  
    padding-top: 10px !important; 
    padding-bottom: 10px !important; 
    padding-right: 40px;
    border-radius: 30px;

    cursor: pointer; 

    position: absolute;
    right: 0;


    // padding: 20px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // padding-right: 40px;
    // border-radius: 30px; 
    // position: relative;
    background-color: #eff3f5; 

    line-height: 30px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.8pt;
    &:hover{
        background-color:$blue; 
    }
    }
}